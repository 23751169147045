import { ComponentProps } from "react";
import Metadata from "./Metadata";

interface Props {
  children: React.ReactNode;
  className?: string;
  maxWidth?: "lg" | "xl";
  metadata: ComponentProps<typeof Metadata>;
}

export default function PublicPage(props: Props) {
  const { className, maxWidth = "lg" } = props;

  const maxWidths: Record<NonNullable<Props["maxWidth"]>, string> = {
    lg: "max-w-screen-lg",
    xl: "max-w-screen-xl",
  };
  return (
    <div
      className={`bg-theme-white mx-auto flex w-full max-w-screen-lg flex-1 flex-col px-3 py-10 sm:px-6 sm:py-20 ${maxWidths[maxWidth]} ${className}`}
    >
      <Metadata {...props.metadata} />

      {props.children}
    </div>
  );
}
