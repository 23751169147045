import React from "react";
import cx from "classnames";

interface Props {
  rating: number;
  theme?: "gold" | "white";
}

export default function StarRating(props: Props) {
  const { rating, theme = "gold" } = props;

  return (
    <div className="flex items-center space-x-1">
      {new Array(Math.round(rating)).fill(null).map((r, i) => (
        <div
          key={i}
          className={`${cx({
            "bg-theme-yellow text-white": theme === "gold",
            "bg-white text-theme-blue": theme === "white",
          })} py-px px-0.5 text-xs`}
        >
          &#9733;
        </div>
      ))}
    </div>
  );
}
