import { Combobox } from "@headlessui/react";
import React from "react";
import SearchWrapper from "./SearchWrapper";
import SearchIcon from "components/icons/search.svg";

export default function SearchBox() {
  return (
    <SearchWrapper>
      {({ setQuery }) => (
        <label
          htmlFor="search"
          className="relative flex w-full items-center rounded-full border border-gray-100 bg-white text-black shadow-lg"
        >
          <Combobox.Input
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.target.select();
            }}
            id="search"
            onChange={(event) => setQuery(event.target.value)}
            autoComplete="off"
            placeholder="Location of event"
            className="peer flex h-full w-full items-center rounded-l-full py-5 pl-6  placeholder:text-gray-700 focus-within:outline-none"
          />
          <div className="border-l-2">
            <button
              type="submit"
              className="mr-3 ml-4 rounded-full bg-theme-blue p-3"
            >
              <SearchIcon />
            </button>
          </div>
        </label>
      )}
    </SearchWrapper>
  );
}
