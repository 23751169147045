export function formatAsCurrency(amount: number | bigint, currency: string) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
}

export function getInitials(name: string) {
  const parts = name.split(" ");
  const first = parts[0]?.at(0) ?? "";
  const last = (parts.length > 1 ? parts[parts.length - 1]?.at(0) : "") ?? "";

  return `${first}${last}`;
}
