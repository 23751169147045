import Piper1 from "images/piper-1.jpg";
import Piper2 from "images/piper-2.jpg";
import Image from "next/image";
import StarRating from "components/search/StarRating";
import { format } from "date-fns";
import { api } from "utils/api";

import SearchBox from "components/SearchBox";

import CheckmarkIcon from "components/icons/checkmark.svg";
import ClockIcon from "components/icons/clock.svg";
import MapIcon from "components/icons/map.svg";
import PinIcon from "components/icons/pin.svg";

import SearchIcon from "components/icons/paper-search.svg";
import CalendarIcon from "components/icons/calendar-money.svg";
import CheckmarkLargeIcon from "components/icons/checkmark-large.svg";
import { getInitials } from "utils/string-formatting";
import PublicPage from "components/PublicPage";

export default function HomePage() {
  const { data: reviews } = api.reviews.curated.useQuery();

  return (
    <PublicPage
      maxWidth="xl"
      className="items-center justify-center space-y-56"
      metadata={{
        title: "Pipers for Hire – Find Talented, Local Bagpipers",
        description:
          "Pipers For Hire provides a full-service platform for finding local bagpipers to perform for your next event. Read reviews, get quotes, and book a bagpiper today.",
        url: "https://pipersforhire.com",
      }}
    >
      <section className="flex w-full flex-col items-center justify-between lg:flex-row">
        <div className="flex-1">
          <div className="">
            <h1 className="text-3xl font-bold sm:text-6xl">
              Find a bagpiper for your next event
            </h1>
            <p className="mt-5 text-xl font-semibold text-black/60">
              Simply enter the address for the event below to see local
              bagpipers ready for hire. Get free quotes and book a piper today.
            </p>
            <div className="mt-7">
              <SearchBox />
            </div>
          </div>
        </div>
        <div className="relative mt-28 flex flex-1 justify-end lg:mt-0">
          <Image
            src={Piper1}
            alt="Bagpiper 1"
            className="-translate-y-12 rounded-xl"
          />
          <Image
            src={Piper2}
            alt="Bagpiper 2"
            className="ml-6 translate-y-12 rounded-xl"
          />
          <div className="absolute -bottom-10 left-1/2 -ml-20 flex w-max flex-col rounded-2xl bg-white px-4 py-2 text-xs shadow-lg">
            <p className="text-sm font-semibold">Bennie Griffen</p>
            <div className="mt-1 flex items-center">
              <StarRating rating={5} />
              <span className="ml-2">5.0 (125 reviews)</span>
            </div>
            <div className="mt-2 flex flex-col space-y-2">
              <div className="flex items-center space-x-3">
                <ClockIcon /> <span>Member Since 2016</span>
              </div>
              <div className="flex items-center space-x-3">
                <CheckmarkIcon /> <span>240 Verified Bookings</span>
              </div>
              <div className="flex items-center space-x-3">
                <MapIcon /> <span>Travels up to 1500 miles</span>
              </div>
              <div className="flex items-center space-x-3">
                <PinIcon /> <span>Nashville, TN</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mx-auto flex flex-col items-center">
        <h2 className="text-center text-4xl font-bold sm:text-5xl">
          How it Works
        </h2>
        <p className="mx-auto mt-4 max-w-3xl text-center text-xl font-semibold text-black/60">
          Whether planning a wedding, funeral, birthday party, or other event -
          Pipers For Hire makes it simple to find and book bagpipers in your
          area.
        </p>
        <div className="mt-20 flex w-full flex-col items-center justify-between space-y-12 lg:flex-row lg:space-x-12 lg:space-y-0">
          <HowItWorksBlock
            title="Search for local talent"
            description="We'll show you available bagpipers
              ready to perform for your event."
            Icon={SearchIcon}
          />
          <HowItWorksBlock
            title="Get free quotes"
            description="Browse profiles, read reviews, and get
              free quotes from one or multiple pipers."
            Icon={CalendarIcon}
          />
          <HowItWorksBlock
            title="Book your bagpiper"
            description="Select a bagpiper and enjoy
              live bagpipe music at your event!"
            Icon={CheckmarkLargeIcon}
          />
        </div>
      </section>
      <section className="mx-auto flex w-full max-w-screen-xl flex-col items-center">
        <h2 className="text-center text-4xl font-bold sm:text-5xl">
          Trusted by Thousands
        </h2>
        <div className="mt-8 flex w-full max-w-screen-md items-center justify-center text-center text-gray-500">
          <div className="">
            <div className="text-xl font-semibold">5,000+</div>
            <div className="font-light italic">events booked</div>
          </div>
          <div className="flex-1">
            <div className="text-xl font-semibold">4.8</div>
            <div className="font-light italic">
              stars average performer rating
            </div>
          </div>
          <div className="">
            <div className="text-xl font-semibold">20 Years+</div>
            <div className="font-light italic">of highest quality service</div>
          </div>
        </div>
        <div className="mt-14 grid w-full grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {reviews?.map((r) => (
            <ReviewCard
              key={r.id}
              name={r.reviewerName}
              image={r.reviewer?.profileImage}
              rating={r.rating}
              body={r.body}
              date={r.createdAt}
            />
          ))}
        </div>
      </section>
    </PublicPage>
  );
}

const HowItWorksBlock = ({
  title,
  description,
  Icon,
}: {
  title: string;
  description: string;
  Icon: any;
}) => {
  return (
    <div className="flex  max-w-sm flex-col items-center">
      <div className="flex h-16 items-end">
        <Icon />
      </div>
      <div className="mt-4 text-2xl font-semibold">{title}</div>
      <p className="mt-2 text-center text-lg">{description}</p>
    </div>
  );
};

const ReviewCard = ({
  name,
  image,
  rating,
  body,
  date,
}: {
  name: string;
  image: string | null | undefined;
  rating: number;
  body: string | null;
  date: Date;
}) => {
  return (
    <div className="flex flex-col place-self-start rounded-lg border border-black/20 px-7 py-2.5">
      <div className="mb-3 flex items-center space-x-2">
        <div className="flex h-12 w-12 items-center justify-center overflow-hidden rounded-full">
          {image ? (
            <Image
              src={image}
              alt={name}
              width="48"
              height="48"
              className="object-cover"
            />
          ) : (
            <div className="flex h-48 w-48 flex-shrink-0 items-center justify-center rounded-full bg-gray-200 text-xl font-bold text-gray-700">
              {getInitials(name)}
            </div>
          )}
        </div>
        <div className="text-sm font-semibold">{name}</div>
      </div>
      <div className="flex items-center space-x-1">
        {new Array(Math.round(rating)).fill(null).map((r, i) => (
          <div key={i} className=" text-theme-yellow">
            &#9733;
          </div>
        ))}
      </div>
      <p className="mt-3 text-xs">{body}</p>

      <div className="mt-4 text-xs opacity-50">
        {format(date, "MMMM do, yyyy")}
      </div>
    </div>
  );
};
